import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import FormattedMessage from './FormattedMessage';
import { colors } from '../styles/colors';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const ChatBoxContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  border-radius: 8px;
  margin-right: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-width: 800px;
  width: 100%;
`;

const MessageList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
`;

const Message = styled.div`
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  max-width: 90%;
  font-family: Arial, sans-serif;
  ${props => props.isuser === 'true' ? `
    align-self: flex-end;
    background-color: ${colors.primaryLight};
    color: ${colors.textLight};
    margin-left: auto;
  ` : `
    align-self: flex-start;
    background-color: ${colors.secondary};
    color: ${colors.text};
    margin-right: auto;
  `}

  @media (max-width: 480px) {
    max-width: 95%;
  }
`;

const InputArea = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  background-color: ${colors.primaryDark};
`;

const Input = styled.input`
  width: 70%;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 20px;
  background-color: ${colors.textLight};
  color: ${colors.text};
  font-size: 1rem;
  font-family: Arial, sans-serif;
  outline: none;

  &:focus {
    outline: none;
  }
`;

const SendButton = styled.button`
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 20px;
  background-color: ${colors.accent};
  color: ${colors.text};
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  font-family: Arial, sans-serif;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${colors.primaryLight};
    color: ${colors.textLight};
  }

  &:disabled {
    background-color: ${colors.primaryDark};
    color: ${colors.textLight};
    cursor: not-allowed;
  }
`;

const Question = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const LoadingMessage = styled.div`
  text-align: center;
  padding: 1rem;
  font-style: italic;
`;

function ChatBox({ messages, addMessage, input, setInput }) {
  const [isLoading, setIsLoading] = useState(false);
  const messageListRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [input]);

  const sendMessage = async () => {
    if (input.trim() === '') return;
  
    const userMessage = { type: 'user', content: input, isUser: true };
    addMessage(userMessage);
    setInput('');
    setIsLoading(true);
  
    try {
      const response = await axios.post(`${API_URL}/chat`, {
        message: input,
        session_id: 'default',
        timestamp: Date.now() / 1000
      });
  
      const aiMessage = { type: 'ai', content: response.data.response, isUser: false };
      addMessage(aiMessage);
    } catch (error) {
      console.error('Error sending message:', error);
      addMessage({ type: 'error', content: 'An error occurred while processing your request.', isUser: false });
    }
  
    setIsLoading(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <ChatBoxContainer>
      <MessageList ref={messageListRef}>
        {messages.map((message, index) => (
          <Message key={index} isuser={(message.isUser || false).toString()}>
            {message.isUser ? (
              <Question>{message.content}</Question>
            ) : (
              <FormattedMessage text={message.content} />
            )}
          </Message>
        ))}
        {isLoading && <LoadingMessage>AI is thinking...</LoadingMessage>}
      </MessageList>
      <InputArea>
        <Input
          ref={inputRef}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your message here..."
          disabled={isLoading}
        />
        <SendButton onClick={sendMessage} disabled={isLoading || !input.trim()}>
          Send
        </SendButton>
      </InputArea>
    </ChatBoxContainer>
  );
}

export default ChatBox;